<template>
	<div class="home">

		<v-container>
			<v-row>
				<v-col cols="12" sm="6">
					<v-card
					class="mx-auto">
					<v-card-title><a href="lote1">Contraindicaciones/Poblaciones Especiales</a></v-card-title>
					<v-card-text>
						<v-simple-table>
							<template v-slot:default>

								<tbody>
									<tr>
										<td>Medicamento</td>
										<td>Paracetamol</td>

									</tr>
									<tr>
										<td>Vías</td>
										<td>Oral</td>

									</tr>
									<tr>
										<td>Forma Farmacéutica</td>
										<td>Comprimido </td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" sm="6">
				<v-card class="mx-auto">
					<v-card-title>
						<a href="lote2">Duplicidades Terapéuticas</a>
					</v-card-title>
					<v-card-text>
						<v-simple-table>
							<thead>
								<th>
									Principio 1
								</th>

								<th>Principio 2</th>

							</thead>
							<tbody>
								<tr>
									<td>
										<v-row>
											<v-col>ATC OMS:</v-col> <v-col>C07AA05
											</v-col>
										</v-row>
										<v-row>
											<v-col>Descripción Principio:</v-col> <v-col>Propranolol
											</v-col>
										</v-row>
										<v-row>
											<v-col>SubGrupo Químico:</v-col> <v-col>C07AA
											</v-col>
										</v-row>
										<v-row>
											<v-col>Descripción SubGrupo:</v-col> <v-col>Beta-bloqueantes no selectivos
											</v-col>
										</v-row>
										<v-row>
											<v-col>Vía:</v-col> <v-col>Oral</v-col>
										</v-row>
										<v-row>
											<v-col>Forma:</v-col> <v-col>
												<ul>
													<li>
														Comprimidos recubiertos con película

													</li>
													<li>Solución Oral</li>
												</ul>

											</v-col>
										</v-row>
									</td>

									<td>
										<v-row>
											<v-col>ATC OMS:</v-col> <v-col> C07AB03
											</v-col>
										</v-row>
										<v-row>
											<v-col>Descripción Principio:</v-col> <v-col>Atenolol
											</v-col>
										</v-row>
										<v-row>
											<v-col>SubGrupo Químico:</v-col> <v-col>C07AB
											</v-col>
										</v-row>
										<v-row>
											<v-col>Descripción SubGrupo:</v-col> <v-col>Beta-bloqueantes selectivos
											</v-col>
										</v-row>
										<v-row>
											<v-col>Vía:</v-col> <v-col>Oral
											</v-col>
										</v-row>
										<v-row>
											<v-col>Forma:</v-col> <v-col><ul>
												<li>
													Comprimidos recubiertos con película
												</li>
												<li>
													Comprimidos recubiertos

												</li>
												<li>
													Comprimidos

												</li>
											</ul>
										</v-col>
									</v-row>
								</td>
							</tr>
						</tbody>
					</v-simple-table>	
				</v-card-text>

			</v-card>
		</v-col>
	</v-row>
	<v-row>
		<v-col cols="12" sm="6">
			<v-card class="mx-auto">
				<v-card-title><a href="lote3">Interacciones Farmacológicas</a></v-card-title>
				<v-card-text>
					<v-simple-table>
						<thead>
							<th>
								Principio 1
							</th>

							<th>Principio 2</th>

						</thead>
						<tbody>
							<tr>
								<td>

									<v-row>
										<v-col>Descripción Principio:</v-col> <v-col>Propranolol
										</v-col>
									</v-row>
									<v-row>
										<v-col>ATC OMS:</v-col> <v-col>C07AA05
										</v-col>
									</v-row>
									<v-row>
										<v-col>DESCRIPCIÓN ATC:</v-col> <v-col>C07AA05
										</v-col>
									</v-row>

									<v-row>
										<v-col>Vía:</v-col> <v-col>Oral</v-col>
									</v-row>
									<v-row>
										<v-col>Vía Sistémica:</v-col> <v-col>SÍ</v-col>
									</v-row>

								</td>

								<td>
									<v-row>
										<v-col>Descripción Principio:</v-col> <v-col>Verapamilo
										</v-col>
									</v-row>
									<v-row>
										<v-col>ATC OMS:</v-col> <v-col>3203CH
										</v-col>
									</v-row>
									<v-row>
										<v-col>DESCRIPCIÓN ATC:</v-col> <v-col>Verapamilo
										</v-col>
									</v-row>
									
									<v-row>
										<v-col>Vía:</v-col> <v-col>Oral, Intravenosa</v-col>
									</v-row>
									<v-row>
										<v-col>Vía Sistémica:</v-col> <v-col>SÍ</v-col>
									</v-row>
								</td>
							</tr>
						</tbody>
					</v-simple-table>	


				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12" sm="6">
			<v-card class="mx-auto">
				<v-card-title><a href="lote4" >Posología y Dosis Máximas</a></v-card-title>
				<v-card-text>
					<v-simple-table>
						
						<tbody>
							<tr>
								<td>
									<v-row>
										<v-col>CN Medicamento:</v-col> <v-col>Paracetamol
										</v-col>
									</v-row>
									<v-row>
										<v-col>Descripción:</v-col> <v-col>Propranolol
										</v-col>
									</v-row>
									<v-row>
										<v-col>Código ATC OMS:</v-col> <v-col>C07AA05
										</v-col>
									</v-row>

									<v-row>
										<v-col>Descripción Principio  :</v-col> <v-col> Paracetamol </v-col>
									</v-row>
									<v-row>
										<v-col>Vía :</v-col> <v-col>Oral</v-col>
									</v-row>
									

								</td>


							</tr>
						</tbody>
					</v-simple-table>	
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
	<v-row>
		<v-col cols="12" sm="6">
			<v-card class="mx-auto cardHightlight">
				<v-card-title class="titleCentered"><a href="lote5" >ANÁLISIS PRESCRIPCIÓN</a></v-card-title>
				
			</v-card>
		</v-col>

		<v-col cols="12" sm="6">
			<v-card class="mx-auto cardHightlight">
				<v-card-title class="titleCentered"><a href="fap" >REVISTrA - Reorientación farmacéutica para FAP</a></v-card-title>
				
			</v-card>
		</v-col>
	</v-row>
</v-container>
</div>
</template>
<style lang="scss"> 
.cardHightlight{
	background: rgba(200,200,200,0.6)!important;
	color:white;
	a{
	text-decoration:none;
	color:initial;


}

}
.titleCentered{
	text-align:center;
	margin:auto;
	width: auto;
	border:1px solid;
	display:flex;
	align-items:center;
	justify-content:center;
	a{
	text-align:center;
	display: flex;
}
}

</style>
<script>
// @ is an alias to /src


export default {
	name: 'Home',   
}
</script>
