<template>
  <div id="app">
    <app-bar :statusUser="userStatus"></app-bar>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6"><login :statusUser="userStatus"></login></v-col>
        <v-col cols="12" sm="6">
          <tarjeta-paciente></tarjeta-paciente>
        </v-col>

      </v-row>
    </v-container>
    <router-view id="routerview"></router-view>
    <v-container>
      <v-row>
        
        <state-bar :page="currentRouteName" ></state-bar>      
      
      </v-row>
    
    </v-container>

  </div>
</template>


<script>
import AppBar from './components/AppBar.vue';
import TarjetaPaciente from './components/TarjetaPaciente.vue';
import Login from './components/Login.vue';
import StateBar from './components/BarraEstado.vue';
export default{
  components:{
    AppBar,
    TarjetaPaciente,
    StateBar,
    Login

  },
  data: () => ({   
  userStatus:true,    
  }), 
  computed: {
    statusUser(){return localStorage.getItem('statusUser')},
    currentRouteName() {
        return this.$route.name;
    }
}  
}
</script>

<style lang="scss">

@import "@/assets/_global.scss";


</style>
