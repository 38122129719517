<template>
	<div>
		<v-app-bar       
		>
		<v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

		<v-toolbar-title class="titleApp"><a href="/">{{ titleApp }}  
			<img  
			style="margin-left:20px;"
			height="40"
			src="@/assets/Semi-logo-SERMAS-15x15-mm5-200x200.jpg"></a>



		</v-toolbar-title>
		<v-spacer></v-spacer>
		<v-col class="boxName" cols="6" md="2">
			<div>
				<v-row>
					<v-col cols="4" md="5"  >
						<div class="headline">{{ user.name }} </div>
						<span class="grey--text">3581</span>
					</v-col>
					<v-col cols="1" md="1" >
						<v-icon  large>mdi-account</v-icon>
					</v-col>
				</v-row> 
			</div>
		</v-col>
	</v-app-bar>

	<v-navigation-drawer
	v-model="drawer"
	absolute
	temporary
	>
	<v-list
	nav
	dense
	>
	<v-list-item-group
	v-model="group"
	active-class="deep-purple--text text--accent-4"
	>
	<v-list-item v-for="(option, index) in menuOptions " :key="index" class="itemList">
		<template v-if="option.url=='lote5'">


			<v-list-item-title class="iteml5"><a :href="option.url">{{ option.name }}</a></v-list-item-title>

		</template>
		<template v-else>
			<v-list-item-title><a :href="option.url">{{ option.name }}</a></v-list-item-title>
		</template>
	</v-list-item>

	<v-list-item v-if="userStatus">
		<v-list-item-icon >
			<v-icon>mdi-account</v-icon>
		</v-list-item-icon>
		<v-list-item-title >Mi Perfil</v-list-item-title>
	</v-list-item>
	<v-list-item>
		<v-list-item-icon>
			<v-icon>mdi-exit</v-icon>
		</v-list-item-icon>
		<v-list-item-title>Cerrar Sesión</v-list-item-title>
	</v-list-item>
</v-list-item-group>
</v-list>
</v-navigation-drawer>
</div>
</template>
<style lang="scss">

.itemList a{
	text-decoration:none;
}
.iteml5{
	border-top:1px solid black;
	padding-top:10px;

}
</style>
<script>
export default{
	data: () => ({
		titleApp: 'ESTACIÓN AYUDA A LA PRESCRICIPCIÓN FARMACOLÓGICA',
		user:{
			name: 'MAR LÓPEZ',
			userId: '3581',
		},
		drawer: false,
		group: null,

		menuOptions:[
		{
			name:'Inicio', url:'/'
		},
		{ 
			name: 'Filtro Lote1: Contraindicaciones/Precauciones y Poblaciones especiales', url: 'lote1'},
			{
				name: 'Filtro Lote2: Duplicidades Terapéuticas', url:'lote2',			
			},
			{
				name: 'Filtro Lote3: Interacciones Farmacológicas', url: 'lote3',
			},
			{
				name: 'Filtro Lote4: Posología y dosis máxima', url: 'lote4'
			},
			{
				name: 'Analisis de la Prescripción', url:'lote5'
			},
			{
				name:'REVISTrA - Reorientación farmacéutica para FAP', url:'fap'
			}

			]
		}),   
}
</script>