<template>
	<div class="div-breadcrumb">
		<ul class="breadcrumbs">
			<li v-for="(item,key) in estadoList" :key="key">
				<a :href="item.url" :class="item.status">
					<span class="number">{{ key+1}}</span>
					{{ item.name }}
				</a>
			</li>		

		</ul>
		<ul class="breadcrumbs breadcrumbs--extra">
			<li v-for="(item,key) in estadoList2" :key="key">
				<a :href="item.url" :class="item.status">				
					{{ item.name }}
				</a>
			</li>	
		</ul>
	</div>
</template>

<style lang="scss">
/*********************************/
/*********************************/

$incomplete: #000;
$completed: #36699C;
$hightlight: green;
$current: lighten(#FF0000, 15%);
$pulse-length: 1s;
.div-breadcrumb{
	width: 80%;
	margin:auto;
}

ul.breadcrumbs {	
	margin:   50px auto;
	margin-bottom:20px;
	list-style: none;
	&:after {
	clear: both;
	content: "";
	display: table; 
}
&.breadcrumbs--extra{
	margin:10px auto;
}



& li {
	display: block;
	float: left;
	margin: 0px;
	padding: 0px;

	& a {
	cursor: pointer;
	display: inline-block;
	background-color: $incomplete;
	color: white;
	line-height: 40px;
	padding: 0 35px 0 50px;
	position: relative;
	box-shadow: 0px 0px 10px $incomplete;
	font-size:14px;

	&:after, &:before {
	display: block;
	position: absolute;
	content: "";
	background-color: $incomplete;
}

&:after {
	right: -15px;
	height: 28.5px;
	width: 28.5px;
	top: 4px;
	z-index: 5;
	transform: rotate(45deg);
	border-right: 3px white solid;
	border-top: 3px white solid;
	border-radius: 3px;
}

&:before {
	right: 3px;
	width: 30px;
	height: 40px;
	z-index: 6;
}

& span.number {
	border-radius: 50%;
	display: inline-block;
	line-height: 20px;
	background-color: white;
	font-family: "athelas", serif;
	font-weight: bold;
	color: black;
	width: 20px;
	text-align: center;
	margin-right: 5px;
}

&.completed {
	background-color: $completed;
	color: white;
	box-shadow: 0px 0px 10px $completed;

	&:after, &:before {
	background-color: $completed;
}
}

&.hightlight{
	background-color: $hightlight;
	color: white;
	box-shadow: 0px 0px 10px $hightlight;

	&:after, &:before {
	background-color: $hightlight;
}
}

&.current {
	background-color: $current;
	animation-duration: $pulse-length;
	animation-name: pulse, glowPulse;
	animation-direction: alternate;
	animation-iteration-count: infinite;
	box-shadow: 0px 0px 10px $current;

	&:after, &:before {
	background-color: $current;
	animation-duration: $pulse-length;
	animation-name: pulse;
	animation-direction: alternate;
	animation-iteration-count: infinite;
}

&:after {
	animation-name: pulse;
}
}

}

&:first-child a {
	padding: 0 35px;
	border-radius: auto auto auto 3px;
}

&:last-child  a{
	border-radius: auto 3px auto;

	&:after {
	display: none;
}
}

}
}

@keyframes pulse {
from {
background-color:$incomplete;
}

to {
background-color:$current;
}
}

@keyframes glowPulse {
from {
box-shadow: 0px 0px 10px $incomplete;
}

to {
box-shadow: 0px 0px 10px $current;
}
}


/*********************************/
/*********************************/

</style>
<script>
export default{
	props:[
	{
		currentRouteName: {
			type:Boolean
		}
	}],
	data(){
		return {
			estadoList:[
			{ status:'completed', name:'Inicio', url:''},
			{ status:'completed', name:'CONTRAINDICACIONES', url:'lote1'},
			{ status:'completed', name:'DUPLICIDADES TERAPÉUTICAS', url:'lote2'},
			{ status:'current', name:'INTERACCIONES', url:'lote3'},
			{ status:'', name:'POSOLOGIA', url:'lote4'},			


			], 
			estadoList2:[
			{ status:'hightlight', name:'ANÁLISIS PRESCRICIPCIÓN', url:'lote5'},			
			{ status:'hightlight', name:'REVISTrA - Reorientación farmacéutica', url:'fap'},		]
		}
	}
}
</script>