<template>
	<v-card class="cardPatient">
		<div class="row">
			<v-col cols="12" sm="8">
				<v-autocomplete
				v-model="values"
				:items="pacientesList"
				label="Buscador de pacientes"
				></v-autocomplete>
			</v-col>
		</div>		 
		<div class="row">
			<v-col cols="12" sm="8">
				<div class="cardrow">
					<v-col>Nº PACIENTE:</v-col>
					<v-col> ABCD9919K</v-col>
				</div>
				<div class="cardrow">
					<v-col></v-col>
					<v-col>
						<h3>PETER MOUNTAIN</h3>
					</v-col>
				</div>
				<div class="cardrow">
					<v-col>Población:</v-col>
					<v-col>Algete</v-col>
				</div>
				<div class="cardrow">
					<v-col>	H.C.N:</v-col>
					<v-col>zfl143-19</v-col>
				</div>
				<div class="cardrow">
					<v-col>ALERGIAS:</v-col>
					<v-col>Penicilina</v-col>
				</div>
				<div class="cardrow">
					<v-col>Último Diagnóstico:</v-col>
					<v-col>Hipercolesterolemia</v-col>
				</div>
				<div class="cardrow">
					<v-col>Última prescripción: </v-col>
					<v-col>Simvastatina 40mg</v-col>
				</div>
				<div class="cardrow">
					<v-col>Ppio ATC: </v-col>
					<v-col>Simvastatina</v-col>
				</div>
				<div class="cardrow">
					<v-col>Posología:</v-col>
					<v-col>40mg cada 8 horas</v-col>
				</div>
				<div class="cardrow">
					<v-col>Pautas:</v-col>
					<v-col></v-col>
				</div>
				<div class="cardrow">
					<v-col>Antecedentes Patológicos: </v-col>
					<v-col>
						<ul>							
							<li>Hipercolesterolemia</li>
							<li>Hipertensión Arterial</li>
							<li>Diabetes millitus tipo 2</li>
						</ul>
					</v-col>
				</div>
				<div class="cardrow">	
					<v-col>Condición:	</v-col>
					<v-col>	Obesidad grado 2</v-col>
				</div>
				<div class="cardrow">	
					<v-col>Hábitos Tóxicos:	</v-col>
					<v-col>Tabaquismo</v-col>

				</div>
				<div class="cardrow">	
					<v-col>	Medicación en curso:</v-col>
					<v-col>
						<ul>	
						<li>Simvastatina 40mg/día</li>
						<li>Enapril 5mg/día</li>
						<li>Gliclazida 60mg/día</li></ul>
					</v-col>
				</div>
						
				
				<div class="cardrow">
					<v-col>Doctor:</v-col>
					<v-col>Escamilla E. (Col.2234337866E)</v-col>
				</div>
				<div class="cardrow">
					<v-col>Médico Prescriptor:</v-col>
					<v-col>Jose Luis Gómez (Col.1332444566Z)</v-col>
				</div>
			</v-col>
			<v-col cols="12" sm="4" class="motivo-inicial" >
				<div class="cardrow">
					<v-col>	Motivo Incial:</v-col><v-col>	FIEBRE</v-col> 
				</div>
				<div class="cardrow">
					<v-col>	Edad: </v-col><v-col>	56</v-col>
				</div>
				<div class="cardrow"><v-col>Sexo:</v-col><v-col> M</v-col></div>
				<div class="">
					<img src="@/assets/paciente.jpg" alt="" width="100%">
				</div>

			</v-col>
		</div>
	</v-card>
</template>

<style lang="scss">
.cardrow{
	display: grid;
	grid-template-columns: 1fr 1fr;

	[class^='col']{
	padding:5px 10px;
	ul li {
	list-style:none;
}
}
.col:nth-child(2){
	font-weight:bold;
}

}
.cardPatient{
	padding:10px;
}
</style>
<script>
export default{
	name:'TarjetaPaciente',
	data:()=>({
		pacientesList:[
		'Alfredo Álvarez',
		'Bernardo González',
		'Valentina López',
		'Juan Alcántara',
		'Rosario Torres'
		],
		items: ['foo', 'bar', 'fizz', 'buzz'],
		values: ['foo', 'bar'],
		
	})
	
}
</script>