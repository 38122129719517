<template>

	<v-card class="loginCard" >
		<v-form
		ref="form"
		v-model="valid"
		lazy-validation
		v-if="statusUser"
		> 
		<v-row>
			<v-col>

				<v-text-field
				v-model="name"
				label="nombre"
				required
				></v-text-field>
			</v-col>
			<v-col>

				<v-text-field
				v-model="password"	 
				label="password"
				type="password"
				required
				></v-text-field>

			</v-col>
			<v-col cols="3">
				
				<v-btn
				color="teal"				
				@click="entrar"
				>
				Entrar
			</v-btn>
		</v-col>
	</v-row>
	<v-row>
		<v-col cols="4">
			<a href="">Nuevo Usuario</a> 
		</v-col>
		<v-col cols="4">
			<a href="">Recordar Contraseña</a> 
		</v-col>

	</v-row>



	<v-row>

	</v-row>




</v-form>

<v-div v-if="!statusUser">USUARIO LOGINADO  <a href="" @click="logout">Cerrar Sesión</a></v-div>
 
</v-card>
</template>
<style lang="scss">
.loginCard{
	padding:10px;
	padding-bottom:30px;
}

</style>
<script>
export default {
	name:'Login' ,
	props:{
		showForm:{
			type:Boolean
		},
		statusUser:{
			type:Boolean
		}

	},
	data: () => ({
		statusUser:true,
		valid: true,
		name: '',		 
		password: '',		 
		select: null,
		items: [
		'Item 1',
		'Item 2',
		'Item 3',
		'Item 4',
		],
		checkbox: false,
	}),
	computed:{
		userStatus(){
			return localStorage.getItem('userStatus');
		}
	},

	methods: {
		entrar(){
			localStorage.setItem('userStatus',true);
		},
		logout(){
			localStorage.setItem('userStatus',false);
		},
		validate () {
			this.$refs.form.validate()
		},
		reset () {
			this.$refs.form.reset()
		},
		resetValidation () {
			this.$refs.form.resetValidation()
		},
	},
}
</script>